import includeAnalytics from './scripts'
import facebookPixel from './scripts/facebookPixel'
import bingAds from './scripts/bingAds'
import hotjar from './scripts/hotjar'
import googleAnalytics from './scripts/googleAnalytics'
import intercom from './scripts/intercom'
import yahoo from './scripts/yahoo'
import linkedin from './scripts/linkedin'
import { getQueryParams } from './util'

// use Netlify's branch name
const splitTestingNameSpace = !process.env.GATSBY_NETLIFY_BRANCH
  ? undefined
  : process.env.GATSBY_NETLIFY_BRANCH

const initiatedScripts = {
  facebookPixel: facebookPixel({
    pixelId: process.env.GATSBY_ANALYTICS_FB_PIXEL_ID,
    namespace: splitTestingNameSpace,
  }),
  bingAds: bingAds({
    tagId: process.env.GATSBY_ANALYTICS_BING_ADS_ID,
    enableAutoSpaTracking: true,
  }),
  hotjar: hotjar({ hjid: process.env.GATSBY_ANALYTICS_HOTJAR_ID }),
  googleAnalytics: googleAnalytics({
    UA: process.env.GATSBY_ANALYTICS_GOOGLE_UA,
    dimension: { name: 'dimension3', value: splitTestingNameSpace },
  }),
  intercom: intercom({
    app_id: process.env.GATSBY_ANALYTICS_INTERCOM_APP_ID,
    urlParams: getQueryParams().queryString,
  }),
  yahoo: yahoo({
    projectId: process.env.GATSBY_ANALYTICS_YAHOO_PROJECT_ID,
    pixelId: process.env.GATSBY_ANALYTICS_YAHOO_PIXEL_ID,
  }),
  linkedin: linkedin({
    _linkedin_partner_id: process.env.GATSBY_ANALYTICS_LINKEDIN_ID,
  }),
}

const analytics = includeAnalytics(
  // use Object.entries to allow for babel to detect this feature usage and polyfill it (needed fot react-spring but not detected)
  Object.entries(initiatedScripts).map(([, v]) => v),
  {
    simulate: process.env.GATSBY_ENV_NAME !== 'prod',
  },
)

const { pageview, track, lead, user } = analytics

export { initiatedScripts, pageview, track, lead, user }
export default analytics
