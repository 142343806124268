// https://help.bingads.microsoft.com/#apex/3/en/56686/2

export default config => {
  const { tagId } = config
  return {
    config,
    name: `bing ads`,
    snippet: `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[] ,f=function(){var o={ti:'${tagId}',enableAutoSpaTracking:true}; o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")} ,n=d.createElement(t),n.src=r,n.async=1,n.onload=n .onreadystatechange=function() {var s=this.readyState;s &&s!=="loaded"&& s!=="complete"||(f(),n.onload=n. onreadystatechange=null)},i= d.getElementsByTagName(t)[0],i. parentNode.insertBefore(n,i)})(window,document,"script"," https://bat.bing.com/bat.js","uetq");`,
    pageview: () => window.uetq.push('pageLoad'),
    track: ({ eventName, extra = {} }) =>
      window.uetq.push('event', eventName, {
        event_category: extra.category,
        event_label: extra.label,
        event_value: extra.value,
      }),
  }
}
